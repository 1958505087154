export const stretches = [
    {
        'name': 'Camel Pose',
        'body_parts': ['Abs', 'Obliques'],
        'has_sides': false,
        'image': 'stretches/camelpose.webp',
    },
    {
        'name': 'Wide Forward Fold',
        'body_parts': ['Adductor'],
        'has_sides': false,
        'image': 'stretches/wideforwardfold.webp',
    },
    {
        'name': 'Frog Pose',
        'body_parts': ['Adductor'],
        'has_sides': false,
        'image': 'stretches/frogpose.webp',
    },
    {
        'name': 'Wide Side Lunge',
        'body_parts': ['Adductor'],
        'has_sides': true,
        'image': 'stretches/widesidelunge.webp',
    },
    {
        'name': 'Butterfly Stretch',
        'body_parts': ['Adductor'],
        'has_sides': false,
        'image': 'stretches/butterflystretch.webp',
    },
    {
        'name': 'Forearm Extension Stretch',
        'body_parts': ['Forearm'],
        'has_sides': true,
        'image': 'stretches/forearmextension.webp',
    },
    {
        'name': 'Lateral Side of Neck Stretch',
        'body_parts': ['Neck'],
        'has_sides': true,
        'image': 'stretches/lateralsideofneck.webp',
    },
    {
        'name': 'Neck Rotation Stretch',
        'body_parts': ['Neck'],
        'has_sides': true,
        'image': 'stretches/neckrotation.webp',
    },
    {
        'name': 'Neck Extension Stretch',
        'body_parts': ['Neck'],
        'has_sides': false,
        'image': 'stretches/neckextension.webp',
    },
    {
        'name': 'Lateral Side of Neck with Hand Stretch',
        'body_parts': ['Neck'],
        'has_sides': true,
        'image': 'stretches/lateralsideofneckwithhand.webp',
    },
    {
        'name': 'Half Kneeling Quad Stretch',
        'body_parts': ['Quad', 'Hip Flexor'],
        'has_sides': true,
        'image': 'stretches/halfkneelingquadstretch.webp',
    },
    {
        'name': 'Forearm Extension Stretch Down',
        'body_parts': ['Forearm'],
        'has_sides': true,
        'image': 'stretches/forearmextensiondown.webp',
    },
    {
        'name': 'Lateral Shoulder Stretch',
        'body_parts': ['Deltoid'],
        'has_sides': true,
        'image': 'stretches/lateralshoulder.webp',
    },
    {
        'name': 'Standing Neck Flexion Stretch',
        'body_parts': ['Trap'],
        'has_sides': false,
        'image': 'stretches/standingneckflexion.webp',
    },
    {
        'name': 'Lat Stretch with Spinal Traction',
        'body_parts': ['Lat'],
        'has_sides': false,
        'image': 'stretches/latwithspinaltraction.webp',
    },
    {
        'name': 'Lat Stretch at the Wall',
        'body_parts': ['Lat'],
        'has_sides': true,
        'image': 'stretches/latatthewall.webp',
    },
    {
        'name': 'Child\'s Pose',
        'body_parts': ['Lat'],
        'has_sides': false,
        'image': 'stretches/childspose.webp',
    },
    {
        'name': 'Standing Calf Stretch',
        'body_parts': ['Calf'],
        'has_sides': false,
        'image': 'stretches/camelpose.webp',
    },
    {
        'name': 'Front Split',
        'body_parts': ['Hamstring', 'Hip Flexor'],
        'has_sides': true,
        'image': 'stretches/frontsplit.webp',
    },
    {
        'name': 'Seated Forward Fold',
        'body_parts': ['Hamstring', 'Calf'],
        'has_sides': false,
        'image': 'stretches/forwardfold.webp',
    },
    {
        'name': 'Single Leg Forward Bend',
        'body_parts': ['Hamstring'],
        'has_sides': true,
        'image': 'stretches/camelpose.webp',
    },
    {
        'name': 'Deep Squat',
        'body_parts': ['Glute'],
        'has_sides': false,
        'image': 'stretches/deepsquat.webp',
    },
    {
        'name': 'Seated Half King Pigeon Pose',
        'body_parts': ['Glute'],
        'has_sides': true,
        'image': 'stretches/seatedhalfkingpigeonpose.webp',
    },
    {
        'name': 'Standing Calf Stretch at the Wall',
        'body_parts': ['Calf'],
        'has_sides': true,
        'image': 'stretches/standingcalfstretchatthewall.webp',
    },
    {
        'name': 'Lateral Flexion at the Wall',
        'body_parts': ['Obliques'],
        'has_sides': true,
        'image': 'stretches/lateralflexionatthewall.webp',
    },
    {
        'name': 'Supine Twist',
        'body_parts': ['Glutes', 'Obliques'],
        'has_sides': true,
        'image': 'stretches/supinetwist.webp',
    },
    {
        'name': 'Lateral Flexion with a Dowel',
        'body_parts': ['Lat', 'Obliques'],
        'has_sides': true,
        'image': 'stretches/lateralflexionwithdowel.webp',
    },
    {
        'name': 'Triangle Pose',
        'body_parts': ['Obliques'],
        'has_sides': true,
        'image': 'stretches/trianglepose.webp',
    },
    {
        'name': 'Chest Stretch at the Wall',
        'body_parts': ['Pectoral'],
        'has_sides': true,
        'image': 'stretches/chestatthewall.webp',
    },
    {
        'name': 'Seated Half Pigeon Variation',
        'body_parts': ['Tibalis Anterior'],
        'has_sides': true,
        'image': 'stretches/seatedhalfpigeonvariation.webp',
    },
    {
        'name': 'Supine Shoulder External Rotation Stretch',
        'body_parts': ['Rotator Cuff'],
        'has_sides': true,
        'image': 'stretches/supineshoulderexternalrotation.webp',
    },
    {
        'name': 'Down Dog Variation at the Wall',
        'body_parts': ['Pectoral', 'Lat'],
        'has_sides': false,
        'image': 'stretches/downdogvariationatthewall.webp',
    },
];